.welcome-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-modal {
    padding: 20px;
    position: fixed;
    width: 400px;
    background-color: white;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
}

.welcome-title {
    font-size: 20px;
    font-weight: 600;
}

.welcome-content {
    text-align: center;
    padding-bottom: 20px;
}
.welcome-content p {
    margin: 0;
    margin-bottom: 10px;
}

.welcome-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background-color: white;
    color: rgb(58, 58, 58);
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
}

.welcome-close-button:hover {
    background-color: gainsboro;
}

@media only screen and (max-width: 600px) {
    .welcome-modal {
        width: 300px;    }
  }

