body {
  margin: 0;
  overflow: hidden;
  background-color: black;
  font-family: Roboto;
  font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.iframe {
  width: 100vw;
  height: 100vh;
  border: 0;
  background-color: black;
}

.btn {
  margin: 4px;
  border-radius: 27px;
  padding: 14px 26px;
  border-width: 0;
  cursor: pointer;
  font-size: 16px;
}

.btn:hover {
  filter: brightness(90%);
}

.btn-primary {
  color: #FFFFFF;
  background: #1492E8;
}

.btn-secondary {

}